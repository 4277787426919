<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import { ButtonType, ButtonSize } from '@/features/theme/base/models/ButtonType';
import { IssueDetails } from '@/features/issues/models';
import { PillSize } from '@/features/theme/base/models/PillProps';
import IssuePhasePill from '@/features/issues/components/IssuePhasePill.vue';
import { computed, ref } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import useIssue from '../../composables/useIssue';
import IssueRelatedBox from '../IssueRelatedBox.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import useDownloadFiles from '@/features/files/composables/useDownloadFiles';
import useAuthStore from '@/features/auth/useAuthStore';
import { useI18n } from 'vue-i18n';
import useDateFormatting from '@/features/composables/useDateFormatting';
import useIssuePhaseReviewDates from '@/features/issues/composables/useIssuePhaseReviewDates';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import { UserRoleType } from '@/generated/graphql';
import useIssueLegalReference from '@/features/issues/composables/useIssueLegalReference';
import ChangeReviewEndDate from './ChangeReviewEndDate.vue';
import ChangeResponsibleParty from './ChangeResponsibleParty.vue';
import useAuthUser from '@/features/auth/useAuthUser';
import { useAccessControl } from '@/features/auth/useAccessControl';
import useActiveIssue from '@/features/issueProcess/composables/useActiveIssue';
import { issueYearOrderPath } from '@/features/issues/issueYearOrderPath';
import Spinner from '@/features/theme/base/Spinner.vue';

const props = defineProps<{
  issue: IssueDetails;
  editing: boolean;
}>();

const authStore = useAuthStore();
const { issueNumberFormatted, issuePublishDateFormatted } = useIssue(computed(() => props.issue));
const { formatDateLocalized } = useDateFormatting();
const { basicInfoPhaseSelected, activePhaseSelected, selectedPhase } = useActiveIssue(computed(() => props.issue));
const { isOpenForReview, isReviewPhase, reviewStartDate, reviewEndDate } = useIssuePhaseReviewDates(selectedPhase);
const { t } = useI18n();

// Body Visible is only for mobile
const bodyVisible = ref<boolean>(false);
const onHeaderClick = () => {
  bodyVisible.value = !bodyVisible.value;
};

const { getArchiveIssueUrl, downloadFileUrl, fetchFileObjectUrl } = useDownloadFiles();

const archiveIssueWorking = ref(false);
const onArchiveIssueClick = async (issueid: ID, fileName: string) => {
  if (issueid != null && !archiveIssueWorking.value) {
    archiveIssueWorking.value = true;
    const url = getArchiveIssueUrl(issueid);
    const objectUrl = await fetchFileObjectUrl(url);
    downloadFileUrl(objectUrl, fileName);
    archiveIssueWorking.value = false;
  }
};

const showArchive = computed(() => {
  return authStore.isAdmin || authStore.isCommunity;
});

const { getProcessPhaseKey } = useProcessTranslation();

const { getProcessLegalTooltipText, getPhaseLegalTooltipText } = useIssueLegalReference(
  computed(() => props.issue.process),
  computed(() => props.issue.currentPhase)
);

const conjugateDelegation = (delegation: string) => {
  const [delegationName, ...rest] = delegation.split(' ');
  let delegationConjugated = delegationName;
  if (delegationName.endsWith('ur')) {
    delegationConjugated = delegationName.slice(0, -2);
  } else if (delegationName.endsWith('r')) {
    delegationConjugated = delegationName.slice(0, -1);
  } else if (delegationName.endsWith('a')) {
    delegationConjugated = `${delegationName.slice(0, -1)}u`;
  }
  return [delegationConjugated, ...rest].join(' ');
};

const createdBy = computed(() => {
  const { user, delegation } = props.issue;
  let msg = user.fullName;
  const slsRoles = [
    UserRoleType.Admin,
    UserRoleType.SlsEmployeeAdalskipulagssvid,
    UserRoleType.SlsEmployeeDeiliskipulagssvid,
    UserRoleType.SlsEmployeeUmhverfismatssvid,
  ];

  if (delegation) {
    msg += `, í umboði fyrir ${conjugateDelegation(delegation.entityName)}`;
  } else if (user.role && slsRoles.includes(user.role)) {
    msg += ', Skipulagsstofnun';
  }

  return msg;
});

const issueContacts = computed(() => props.issue.contacts);

const changeReviewDatesDialogVisible = ref<boolean>(false);

const onCloseChangeDateDialog = async () => {
  changeReviewDatesDialogVisible.value = false;
};

const changeReviewDatesClick = async () => {
  changeReviewDatesDialogVisible.value = true;
};

const changeResponsiblePartyDialogVisible = ref<boolean>(false);

const onCloseChangeResponsiblePartyDialog = async () => {
  changeResponsiblePartyDialogVisible.value = false;
};

const changeResponsiblePartyClick = async () => {
  changeResponsiblePartyDialogVisible.value = true;
};

const { user } = useAuthUser();
const { canEditIssue } = useAccessControl();

const changeReviewEndDateButtonVisible = computed(() => {
  return props.editing && activePhaseSelected.value && isReviewPhase.value && !basicInfoPhaseSelected.value && user.value && canEditIssue(props.issue);
});

const changeResponsiblePartyButtonVisible = computed(() => {
  return props.editing && authStore.hasCreateAccess.value;
});
</script>

<template>
  <div class="issue-aside">
    <div
      class="aside-header aside-block"
      @click="onHeaderClick"
    >
      <div class="aside-title-text">
        <div class="aside-number block-text">
          <Translate t="issue.issue_no" />
          <span class="mr-1">{{ issueNumberFormatted }}</span>
        </div>
        <div class="bold grey-600">{{ issue.title || '[Heiti máls]' }}</div>
      </div>
      <div class="aside-dropdown">
        <Icon
          class="icon"
          :class="{ 'rotate-180': bodyVisible }"
          icon="DropdownArrow"
          :options="{ color: Colors.black }"
        />
      </div>
    </div>
    <div
      class="aside-body"
      :class="{ hiddenInMobile: !bodyVisible }"
    >
      <div
        class="issuephase-block flex-column"
        v-if="issue.currentPhase"
      >
        <div class="flex">
          <div class="issuephase-block__pill-container">
            <IssuePhasePill
              :pill-size="PillSize.small"
              :issue="issue"
            />
          </div>
          <Button
            :disabled="archiveIssueWorking"
            @click="() => onArchiveIssueClick(issue.id, `[${issue.issueNumber}].zip`)"
            :type="ButtonType.tertiaryIconOnly"
            :size="ButtonSize.small"
            class="ml-1"
            v-tooltip="{ content: t('issue.phase.files.tooltip'), theme: 'primary' }"
          >
            <Icon
              v-if="!archiveIssueWorking"
              icon="Inbox"
            />
            <Spinner v-else />
          </Button>
        </div>
        <!-- Only appear in review phases but not in first step where new review dates are added, to prevent old review dates from former phase from appearing  -->
        <div v-if="issue.currentPhase?.hasReviews && issue.currentPhase?.currentStep?.order !== 1">
          <div
            v-if="!isOpenForReview"
            class="text--small bold issuephase-block__review-dates"
          >
            <Translate t="issue.phase.review_period" />
          </div>
          <div
            v-if="reviewStartDate && reviewEndDate"
            class="text--small issuephase-block__review-dates"
          >
            {{ formatDateLocalized(reviewStartDate) }} -
            {{ formatDateLocalized(reviewEndDate) }}
          </div>
        </div>
      </div>
      <div
        class="issuephase-block flex-column"
        v-if="changeReviewEndDateButtonVisible"
      >
        <Button @click="() => changeReviewDatesClick()">
          <Translate :value="`issue.phase.review.change_date.title`" />
        </Button>
      </div>
      <div class="aside-block">
        <div class="block-title grey-600 text--small bold text-uppercase">
          <Translate t="issue.process" />
        </div>
        <div class="flex gap-3">
          <div class="block-text">
            <Translate :value="issue.process.title" />
          </div>
          <div v-if="getProcessLegalTooltipText && !getProcessLegalTooltipText.endsWith('.legal')">
            <Icon
              icon="QuestionMark"
              :options="{ color: Colors.primary }"
              :height="24"
              :width="24"
              v-tooltip="{ content: getProcessLegalTooltipText, theme: 'primary' }"
            />
          </div>
        </div>
      </div>
      <div
        v-if="issue.currentPhase && issue.currentPhase.name"
        class="aside-block"
      >
        <div class="block-title grey-600 text--small bold text-uppercase">
          <Translate t="issue.phase.state" />
        </div>
        <div class="flex gap-3">
          <div class="block-text">
            <Translate
              :value="getProcessPhaseKey(issue.process, issue.currentPhase)"
              :default-value="issue.currentPhase.name"
            />
          </div>
          <div v-if="getPhaseLegalTooltipText && !getPhaseLegalTooltipText.endsWith('.legal')">
            <Icon
              icon="QuestionMark"
              :options="{ color: Colors.primary }"
              :height="24"
              :width="24"
              v-tooltip="{ content: getPhaseLegalTooltipText, theme: 'primary' }"
            />
          </div>
        </div>
      </div>
      <div class="aside-block">
        <div class="block-title grey-600 text--small bold text-uppercase">
          <Translate t="base.location" />
        </div>
        <div
          class="grey-600"
          v-for="community in issue.communities || []"
          :key="community.id"
        >
          {{ community.name }}
        </div>
      </div>
      <div class="aside-block">
        <div class="block-title grey-600 text--small bold text-uppercase">
          <Translate t="base.created_by" />
        </div>
        <div class="flex">
          <div>
            <div class="block-text">
              {{ createdBy }}
            </div>
            <div class="text--medium">{{ issuePublishDateFormatted }}</div>
          </div>
          <Button
            v-if="changeResponsiblePartyButtonVisible"
            @click="() => changeResponsiblePartyClick()"
            :type="ButtonType.tertiaryIconOnly"
            :size="ButtonSize.small"
            class="ml-1"
            v-tooltip="{ content: t('issue.user.change'), theme: 'primary' }"
          >
            <Icon icon="Refresh" />
          </Button>
        </div>
      </div>
      <div
        v-if="issueContacts.length"
        class="aside-block"
      >
        <div class="block-title grey-600 text--small bold text-uppercase">
          <Translate t="issue.contact.inquiries.to" />
        </div>
        <div v-for="contact in issueContacts">
          <a :href="`mailto:${t(contact.email)}`">
            {{ contact.name }}
          </a>
        </div>
      </div>
      <div
        v-if="issue.relatedIssues.length > 0"
        class="aside-block"
      >
        <div class="block-title grey-600 text--small bold text-uppercase">
          <Translate t="issue.related_issues" />
        </div>
        <ul>
          <li
            class="mb-1"
            v-for="related in issue.relatedIssues"
            :key="related.id"
          >
            <RouterLink :to="issueYearOrderPath(related)">
              <IssueRelatedBox :issue="related" />
            </RouterLink>
          </li>
        </ul>
      </div>
      <ChangeReviewEndDate
        :active="changeReviewDatesDialogVisible"
        :issue="props.issue"
        @yes="onCloseChangeDateDialog"
        @no="onCloseChangeDateDialog"
      />
      <ChangeResponsibleParty
        :active="changeResponsiblePartyDialogVisible"
        :issue="props.issue"
        @yes="onCloseChangeResponsiblePartyDialog"
        @no="onCloseChangeResponsiblePartyDialog"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/colors' as colors;

.aside-block {
  padding: 3.2rem;
  background-color: white;
  border-top: 0.1rem solid colors.$grey-200;
}

.issuephase-block {
  display: flex;
  flex-wrap: wrap;
  padding: 2.6rem;
  padding-right: 3.2rem;
  padding-left: 3.2rem;
  background-color: white;
  border-top: 0.1rem solid colors.$grey-200;
}

.issuephase-block__pill-container {
  display: flex;
  align-items: center;
}

.issuephase-block__review-dates {
  display: flex;
  flex-basis: 100%;
}

.aside-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: colors.$grey-200;

  @include mq.laptop-down() {
    cursor: pointer;
  }
}

.aside-dropdown {
  display: block;

  @include mq.laptop() {
    display: none;
  }
}

.aside-body {
  display: flex;
  flex-direction: column;

  &.hiddenInMobile {
    @include mq.laptop-down() {
      display: none;
    }
  }
}

.block-title,
.block-text {
  margin-bottom: 0.8rem;
}

.icon {
  transition: all 0.3s;
  cursor: pointer;
}

.rotate-180 {
  transform: rotate(180deg);
}

.aside-number {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.aside-edit {
  &:hover {
    opacity: 0.6;
  }
}
</style>
