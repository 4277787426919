<script lang="ts" setup>
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import Alert from '@/features/theme/base/Alert.vue';
import Article from '@/features/theme/base/article/Article.vue';
import ArticleHeader from '@/features/theme/base/article/ArticleHeader.vue';
import UserForm from './UserForm.vue';
import UserTable from './UserTable.vue';
import { User } from '@/features/auth/models';
import useUserManagement, { EditMode } from '@/features/userManagement/composables/useUserManagement';
import { AlertType } from '@/features/theme/base/models/AlertType';
import { computed, ref, watch } from 'vue';
import Modal from '@/features/theme/base/Modal.vue';
import Translate from '@/features/translations/Translate.vue';
import useTranslationModelChecker from '@/features/translations/useTranslationModelChecker';
import { TranslationKey } from '@/features/translations/defaultMessages';
import DelegationMemberForm from './DelegationMemberForm.vue';
import useNavigationStore from '@/features/navigation/useNavigationStore';
import useAuthUser from '@/features/auth/useAuthUser';
import LoadingComponent from '@/features/theme/LoadingComponent.vue';

const { activeDelegation, user } = useAuthUser();
const { users, usersLoading, selectedUser, editMode, setMode, userInput, delegationsInput, isFakeUser, setIsFakeUser, saveChanges, refreshUsers } =
  useUserManagement();

const onCreateClick = () => {
  clearAlert();
  setMode(EditMode.CreateUser);
};

const onEditClick = () => {
  clearAlert();
  setMode(EditMode.EditUser);
};

const onEditDelegationClick = () => {
  clearAlert();
  setMode(EditMode.EditDelegation);
};

const onUserSelected = (user: User) => {
  clearAlert();
  selectedUser.value = user;
};

const onSubmit = () => {
  clearAlert();
  saveChanges()
    .then(user => {
      alertMessage.value = 'user.created';
      alertType.value = AlertType.success;
    })
    .catch(e => {
      alertMessage.value = 'user.failed';
      alertType.value = AlertType.danger;
    });
};

const alertType = ref<Maybe<AlertType>>();
const alertMessage = ref<Maybe<TranslationKey>>();
const clearAlert = () => {
  alertType.value = null;
  alertMessage.value = null;
};

const hasDelegations = computed(() => !!selectedUser.value?.delegations?.length);

const formHeader = ref<TranslationKey>('user.create');

const getFormHeader = computed<TranslationKey>(() => {
  switch (editMode.value) {
    case EditMode.CreateUser:
      formHeader.value = 'user.create';
      break;
    case EditMode.EditUser:
      formHeader.value = 'user.edit';
      break;
    case EditMode.EditDelegation:
      formHeader.value = 'user.delegation.member';
      break;
    case EditMode.None:
    default:
      break;
  }

  return formHeader.value;
});

const onModalClose = () => setMode(EditMode.None);
const { isTranslationKey } = useTranslationModelChecker();

const navStore = useNavigationStore();
navStore.setMainMenuMargin();
navStore.setMainFooterMargin();

watch(user, () => {
  refreshUsers();
});
</script>
<template>
  <LayoutCentered>
    <div class="mb-2">
      <Alert>
        <Translate
          t="user.role.help"
          :interpolations="{
          user: user!.fullName,
          role: $t(`role.${user!.role}`)
        }"
        />
        <Translate
          v-if="isTranslationKey(`user.role.help.${user!.role}`)"
          :value="`user.role.help.${user!.role}`"
        />
      </Alert>
    </div>
    <Modal
      :open="editMode !== EditMode.None"
      @close="onModalClose"
      scrollable
    >
      <Article
        article-size="medium"
        v-if="editMode !== EditMode.None"
      >
        <ArticleHeader>
          <Translate
            :t="getFormHeader"
            :interpolations="{ fullName: selectedUser?.fullName }"
          />
        </ArticleHeader>
        <UserForm
          v-if="[EditMode.CreateUser, EditMode.EditUser].includes(editMode)"
          class="mb-2"
          v-model="userInput"
          @submit="onSubmit"
          :is-fake-user="isFakeUser"
          @update:is-fake-user="setIsFakeUser"
        />
        <DelegationMemberForm
          v-else-if="editMode === EditMode.EditDelegation && selectedUser"
          class="mb-2"
          v-model="delegationsInput"
          @submit="onSubmit"
          :selectedUser="selectedUser"
          :active-delegation-ticket="activeDelegation"
        />
        <Alert
          v-if="alertMessage && alertType"
          :alert-type="alertType"
        >
          <Translate
            :t="alertMessage"
            :interpolations="{ user: selectedUser?.fullName }"
          />
        </Alert>
      </Article>
    </Modal>
    <LoadingComponent v-if="usersLoading" />
    <UserTable
      :selected="selectedUser"
      @select="onUserSelected"
      v-else-if="users.length >= 0"
      :users="users"
      @edit="onEditClick"
      @create="onCreateClick"
      @delegations="onEditDelegationClick"
    />
  </LayoutCentered>
</template>
