<script lang="ts" setup>
import useAuthStore from '@/features/auth/useAuthStore';
import useMediaQuery from '@/features/composables/useMediaQuery';
import Button from '@/features/theme/base/Button.vue';
import DropDown from '@/features/theme/base/DropDown.vue';
import Icon from '@/features/theme/base/Icon.vue';
import InputText from '@/features/theme/base/InputText.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Translate from '@/features/translations/Translate.vue';
import { DelegationTicketInput, GetAvailableDelegatorListsQuery, UserRoleType } from '@/generated/graphql';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  member: DelegationTicketInput;
  delegatorLists?: GetAvailableDelegatorListsQuery;
  delegationName?: Maybe<string>;
  readonly?: boolean;
}>();

const emits = defineEmits<{
  (e: 'delete', member: DelegationTicketInput): void;
}>();

const authStore = useAuthStore();

const { t } = useI18n();

const delegators = computed(() => {
  const list = props.delegatorLists?.availableDelegators.find(d => d.type === props.member.delegationType!);
  return list?.delegators.map(item => ({ name: item.name, value: item.delegationId })) || [];
});

const delegationRoles = computed(() => {
  const list = props.delegatorLists?.availableDelegators.find(d => d.type === props.member.delegationType!);
  return list?.availableRoles.map(role => ({ name: authStore.mapDelegationRoleTranslationKey(role), value: role.toString() })) || [];
});

const isReadOnly = computed(() => {
  if (!authStore.activeDelegation?.delegation) {
    return false;
  }

  return authStore.activeDelegation.delegation.id !== props.member.delegationId;
});

function onDeleteClick() {
  if (!isReadOnly.value) {
    emits('delete', props.member);
  }
}

const delegationReadOnlyValue = computed(() => {
  return props.delegationName;
});

const delegationRoleReadOnlyValue = computed(() => {
  const roleName = props.delegatorLists?.availableDelegators.flatMap(d => d.availableRoles).find(r => r === props.member.role);
  if (roleName) {
    return t(authStore.mapDelegationRoleTranslationKey(roleName));
  }
  return '';
});
const { laptop } = useMediaQuery();
</script>
<template>
  <LayoutFormGroup>
    <template
      class="mb-4"
      #explanation
    >
      <div class="flex flex-align-bottom">
        <p class="bold p--large">
          <Translate
            t="user.delegation.on_behalf_of"
            :interpolations="{ delegatorTypeName: t(`user.delegation.type.${member!.delegationType}`) }"
          />
        </p>
      </div>
    </template>
    <LayoutInput :span="2">
      <DropDown
        v-if="!isReadOnly"
        :model-value="member.delegationId"
        @update:model-value="val => (member.delegationId = val)"
        fullwidth
        label="Umboðsveitendur"
        :options="delegators"
      ></DropDown>
      <InputText
        v-else
        fullwidth
        :model-value="delegationReadOnlyValue"
        label="Umboðsveitendur"
        disabled
      />
    </LayoutInput>
    <LayoutInput :span="laptop ? 1 : 2">
      <DropDown
        v-if="!isReadOnly"
        :model-value="member.role"
        @update:model-value="val => { member.role = val as UserRoleType; }"
        fullwidth
        label="Hlutverk"
        :options="delegationRoles"
      ></DropDown>
      <InputText
        v-else
        fullwidth
        label="Hlutverk"
        :model-value="delegationRoleReadOnlyValue"
        disabled
      />
    </LayoutInput>
    <LayoutInput :span="2">
      <InputText
        fullwidth
        v-model="member.email"
        label="base.email"
        :disabled="isReadOnly"
      />
    </LayoutInput>
    <LayoutInput v-if="!isReadOnly">
      <Button
        @click="onDeleteClick"
        :type="ButtonType.tertiaryIconOnly"
        prevent
      >
        <Icon icon="Delete" />
      </Button>
    </LayoutInput>
  </LayoutFormGroup>
</template>
