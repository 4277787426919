<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import useIssuePhaseState from '../../composables/useIssuePhaseState';
import { Issue, IssuePhase } from '../../models';
import { computed } from 'vue';

const props = defineProps<{
  issue: Issue;
  phase: IssuePhase;
}>();
const { issuePhaseStateString, issuePhaseClosed, issuePhaseSkipped } = useIssuePhaseState(
  computed(() => props.issue),
  computed(() => props.phase)
);

const phaseStateKey = computed(() => {
  if (issuePhaseSkipped.value) {
    return 'issue.phase.state.skipped';
  }

  if (issuePhaseClosed.value) {
    return 'issue.phase.state.closed';
  }

  return issuePhaseStateString.value;
});
</script>

<template>
  <div class="p--small grey-600">
    <Translate :t="phaseStateKey" />
  </div>
</template>
