import { User } from '@/features/auth/models';
import { useGetUsersQuery, DelegationTicketInput, UserInput, UserRoleType } from '@/generated/graphql';
import { computed, ComputedRef, reactive, ref, Ref } from 'vue';
import useUserManagementOperations from './useUserManagementOperations';
import useAuthUser from '@/features/auth/useAuthUser';

interface UseUserManagement {
  users: ComputedRef<User[]>;
  usersLoading: ComputedRef<boolean>;
  selectedUser: Ref<Maybe<User>>;

  editMode: Ref<EditMode>;
  userInput: Ref<UserInput>;
  isFakeUser: IsFakeUser;
  delegationsInput: Ref<DelegationTicketInput[]>;

  setMode(mode: EditMode): void;
  setIsFakeUser(): void;
  saveChanges(): Promise<User>;
  refreshUsers(): Promise<void>;
}

export const enum EditMode {
  None = 'None',
  CreateUser = 'CreateUser',
  EditUser = 'EditUser',
  EditDelegation = 'EditDelegation',
}

export interface IsFakeUser {
  isFake: boolean;
  disabled: boolean;
}

export default function useUserManagement(): UseUserManagement {
  const { activeDelegation } = useAuthUser();
  const { data, executeQuery, fetching } = useGetUsersQuery({ context: { requestPolicy: 'network-only' } });
  const { createUser, createFakeUser, updateUser, addDelegationMember, removeDelegationMember } = useUserManagementOperations();

  const users = computed(() => data?.value?.users || []);
  const selectedUser = ref<Maybe<User>>();

  const editMode = ref<EditMode>(EditMode.None);
  const userInput = ref<UserInput>({});
  const delegationsInput = ref<DelegationTicketInput[]>([]);

  const isFakeUser = reactive<IsFakeUser>({
    isFake: false,
    disabled: true,
  });

  const setIsFakeUser = () => {
    if (!isFakeUser.disabled) {
      isFakeUser.isFake = !isFakeUser.isFake;
    }
  };

  const setMode = (mode: EditMode) => {
    if (mode === EditMode.CreateUser) {
      isFakeUser.isFake = false;
      isFakeUser.disabled = false;

      selectedUser.value = null;
      userInput.value = {
        role: UserRoleType.Public,
      };
    } else if (mode === EditMode.EditUser) {
      if (selectedUser.value) {
        userInput.value = {
          email: selectedUser.value.email,
          fullName: selectedUser.value.fullName,
          nationalId: selectedUser.value.nationalId,
          role: selectedUser.value.role as UserRoleType,
        };

        isFakeUser.isFake = selectedUser.value.isFake;
        isFakeUser.disabled = true;
      }
    } else if (mode === EditMode.None) {
      selectedUser.value = null;
      delegationsInput.value = [];

      isFakeUser.isFake = false;
      isFakeUser.disabled = true;
    }

    editMode.value = mode;
  };

  const saveChanges = async (): Promise<User> => {
    if (editMode.value === EditMode.CreateUser) {
      const user = isFakeUser.isFake ? await createFakeUser(userInput.value) : await createUser(userInput.value);

      selectedUser.value = user;
      setMode(EditMode.EditUser);

      return user;
    } else if (editMode.value === EditMode.EditUser && selectedUser.value) {
      const user = await updateUser(selectedUser.value.id, userInput.value);

      return user;
    } else if (editMode.value === EditMode.EditDelegation && selectedUser.value) {
      let user = selectedUser.value;

      const changedDelegationMembers = delegationsInput.value.filter(
        d => !activeDelegation.value?.delegation || activeDelegation.value.delegation?.id === d.delegationId
      );

      const remainingMembers = changedDelegationMembers.filter(di => !!di.ticketId).map(di => di.ticketId);
      const originalDelegationMembers = selectedUser.value.delegations.filter(
        d => !activeDelegation.value?.delegation || activeDelegation.value.delegation.id === d.delegation!.id
      );
      const originalMembers = originalDelegationMembers.filter(di => !!di.id).map(di => di.id);

      const removedMembers = originalMembers.filter(og => !remainingMembers.includes(og));
      for (const rid of removedMembers) {
        user = await removeDelegationMember(rid);
      }

      for (const added of changedDelegationMembers) {
        user = await addDelegationMember(selectedUser.value?.id, added);
      }

      return user;
    } else {
      throw new Error('Ekki tókst að vista breytingar');
    }
  };

  async function refreshUsers() {
    await executeQuery();
  }

  return {
    users,
    selectedUser,

    editMode,
    userInput,
    isFakeUser,
    setIsFakeUser,
    delegationsInput,

    setMode,
    saveChanges,
    refreshUsers,
    usersLoading: computed(() => fetching.value),
  };
}
