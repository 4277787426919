<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { useGetAvailableDelegatorListsQuery, DelegationType, DelegationTicketInput, DelegationTicket } from '@/generated/graphql';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import DropDown from '@/features/theme/base/DropDown.vue';
import Option from '@/features/theme/base/models/Option';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { User } from '@/features/auth/models';
import DelegationMemberFormGroup from '@/features/userManagement/components/DelegationMemberFormGroup.vue';
import useMediaQuery from '@/features/composables/useMediaQuery';
import keyBy from 'lodash-es/keyBy';

const props = defineProps<{
  selectedUser: User;
  modelValue: DelegationTicketInput[];
  activeDelegationTicket?: Maybe<DelegationTicket>;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', model: DelegationTicketInput[]): void;
  (e: 'submit'): void;
}>();

const onSubmit = () => {
  emits('submit');
};

const delegationNames = computed(() => keyBy(props.selectedUser.delegations, 'delegation.id'));

const inputs: DelegationTicketInput[] = props.selectedUser.delegations.map(d => {
  return {
    delegationId: d.delegation?.id,
    ticketId: d.id,
    email: d.email,
    role: d.role,
    delegationType: d.delegation?.type,
  };
});

props.modelValue.push(...inputs);

const { data: delegatorLists, executeQuery } = useGetAvailableDelegatorListsQuery({
  requestPolicy: 'network-only',
});

const selectedDelegatorType = ref<Maybe<string>>();
const delegatorTypeOptions = computed<Option[]>(() => {
  return (delegatorLists.value?.availableDelegators || []).map(ad => ({
    name: ad.title,
    value: ad.type,
  }));
});

watchEffect(() => {
  if (!selectedDelegatorType.value && delegatorTypeOptions.value.length) {
    selectedDelegatorType.value = delegatorTypeOptions.value[0].value;
  }
});

const onClickAdd = () => {
  props.modelValue.push({
    delegationType: selectedDelegatorType.value as DelegationType,
  });
};

const onClickDelete = (index: number) => props.modelValue.splice(index, 1);

onMounted(() => {
  executeQuery();
});

const { laptop } = useMediaQuery();
</script>

<template>
  <form
    @submit.prevent="onSubmit"
    class="container"
  >
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <DropDown
            :model-value="selectedDelegatorType"
            @update:model-value="val => (selectedDelegatorType = val)"
            fullwidth
            label="user.delegation.type"
            :options="delegatorTypeOptions"
          ></DropDown>
        </LayoutInput>
        <LayoutInput :span="laptop ? 1 : 2">
          <Button
            class="w-100"
            @click="onClickAdd"
            :type="ButtonType.secondary"
            :prevent="true"
          >
            <span class="mr-1">
              <Translate t="base.add" />
            </span>
            <Icon
              icon="Plus"
              :options="{ width: 21, height: 21, color: Colors.primaryDark }"
            />
          </Button>
        </LayoutInput>
      </LayoutFormGroup>
      <DelegationMemberFormGroup
        v-for="(member, index) in modelValue"
        :key="member.ticketId!"
        :member="member"
        :delegator-lists="delegatorLists"
        :delegation-name="member.delegationId && delegationNames[member.delegationId]?.delegation?.entityName"
        @delete="() => onClickDelete(index)"
      />
      <LayoutFormGroup>
        <Button :type="ButtonType.primary">{{ $t('base.save') }}</Button>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
</template>

<style lang="scss" scoped>
.container {
  min-width: 64vw;
}

.flex-align-bottom {
  align-items: flex-end;
}
</style>
