export default function useBodyScrolling() {
  const enableBodyScroll = () => {
    document.querySelector('body')!.style.overflow = 'unset';
  };
  const disableBodyScroll = () => {
    document.querySelector('body')!.style.overflow = 'hidden';
  };
  const isBodyScrollEnabled = () => {
    return document.querySelector('body')!.style.overflow !== 'hidden';
  };
  return {
    enableBodyScroll,
    disableBodyScroll,
    isBodyScrollEnabled,
  };
}
