<script lang="ts" setup>
import { File as DomainFile } from '@/features/files/models';
import { FileType } from '@/features/theme/base/models/ButtonType';
import { computed } from 'vue';
import DownloadButton from '@/features/theme/base/DownloadButton.vue';
import useFileModelChecker from '../composables/useFileModelChecker';

const props = withDefaults(
  defineProps<{
    file: File | DomainFile;
    download?: boolean;
  }>(),
  {
    download: true,
  }
);

const { isDomainFile } = useFileModelChecker();

const fileType = computed<FileType>(() => {
  if (props.file.name?.toLowerCase().includes('pdf')) {
    return FileType.pdf;
  }
  return FileType.doc;
});

const fileName = computed<string>(() => {
  return props.file.name || 'skjal';
});

const filePath = computed<string>(() => {
  return isDomainFile(props.file) ? props.file.path : '';
});
</script>
<template>
  <li class="w-100">
    <DownloadButton
      :download="download"
      :file-type="fileType"
      :file-url="filePath || ''"
    >
      {{ fileName }}
    </DownloadButton>
  </li>
</template>
