<script lang="ts" setup>
import { computed } from 'vue';
import Icon from './Icon.vue';
import { Colors } from './models/Colors';

const props = withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large';
    color?: Colors;
  }>(),
  {
    size: 'medium',
    color: Colors.primary,
  }
);

const wh = computed<number>(() => ({ small: 24, medium: 48, large: 96 }[props.size]));
</script>
<template>
  <Icon
    class="spin"
    icon="Loading"
    :options="{ color, height: wh, width: wh }"
  />
</template>
<style lang="scss" scoped>
.spin {
  animation-name: spin;
  animation-duration: 0.333s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(6);
  animation-fill-mode: forwards;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
