<script lang="ts" setup>
import useMap from '@/features/map/composables/useMap';
import type { MapBrowserEvent } from 'ol';
import { FeatureLike } from 'ol/Feature';
import { Pixel } from 'ol/pixel';
import { onMounted, onUnmounted, ref } from 'vue';

/**
 * @see https://openlayers.org/en/latest/examples/tooltip-on-hover.html
 */

const getMap = useMap();
const tooltip = ref();

const currentFeature = ref<FeatureLike | undefined>();

function displayFeatureTooltip(pixel: Pixel, target: HTMLElement) {
  let feature =
    target.closest('.ol-control') || target.closest('.ol-overlay-container')
      ? undefined
      : getMap().forEachFeatureAtPixel(pixel, function (feature) {
          return feature;
        });

  if (feature?.getId() === undefined) {
    const nestedFeatures = feature?.get('features');
    if (nestedFeatures !== undefined && nestedFeatures.length === 1) {
      feature = feature?.get('features')[0];
    }
  }
  if (feature) {
    tooltip.value.style.left = pixel[0] + 'px';
    tooltip.value.style.top = pixel[1] + 'px';
    if (feature !== currentFeature.value) {
      const featureTooltip = feature.get('FEATURE_TOOLTIP');
      tooltip.value.style.visibility = featureTooltip ? 'visible' : 'hidden';
      tooltip.value.innerText = featureTooltip;
    }
  } else {
    tooltip.value.style.visibility = 'hidden';
  }
  currentFeature.value = feature;
}

function onMapPointerMove(evt: MapBrowserEvent<PointerEvent>) {
  if (evt.dragging) {
    tooltip.value.style.visibility = 'hidden';
    currentFeature.value = undefined;
    return;
  }
  const pixel = getMap().getEventPixel(evt.originalEvent);
  displayFeatureTooltip(pixel, evt.originalEvent.target as HTMLElement);
}

function onMapTargetPointerLeave() {
  currentFeature.value = undefined;
  tooltip.value.style.visibility = 'hidden';
}

onMounted(function () {
  setTimeout(() => {
    getMap().on('pointermove', onMapPointerMove);
    getMap().getTargetElement().addEventListener('pointerleave', onMapTargetPointerLeave);
  }, 200);
});

onUnmounted(function () {
  getMap().un('pointermove', onMapPointerMove);
  getMap().getTargetElement().removeEventListener('pointerleave', onMapTargetPointerLeave);
});
</script>
<template>
  <div
    id="feature-tooltip"
    ref="tooltip"
  ></div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

#feature-tooltip {
  position: absolute;
  display: inline-block;
  height: auto;
  width: auto;
  z-index: 100;
  background-color: colors.$primary--dark;
  border-color: colors.$primary--dark;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 5px;
  left: 50%;
  transform: translateX(3%);
  visibility: hidden;
  pointer-events: none;
}
</style>
