<script lang="ts" setup>
import { computed } from 'vue';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import useCollectionSorting from '@/features/composables/useCollectionSorting';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { useI18n } from 'vue-i18n';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import IssueProgressPhaseState from './IssueProgressPhaseState.vue';
import useAuthUser from '@/features/auth/useAuthUser';

const props = withDefaults(
  defineProps<{
    issue: IssueDetails;
    modelValue?: Maybe<ID>;
  }>(),
  {
    modelValue: null,
  }
);

const emits = defineEmits<{
  (e: 'update:modelValue', phaseId: ID): void;
}>();

const { sortByOrder } = useCollectionSorting();

const orderedPhases = computed(() => sortByOrder(props.issue.phases));

const onPhaseClick = (phase: IssuePhase) => {
  if (props.issue.currentPhase && phase.order <= props.issue.currentPhase?.order && !phase.shouldSkip) {
    emits('update:modelValue', phase.id);
  }
};

const { t, te } = useI18n();
const { getProcessPhaseKey } = useProcessTranslation();

const progressName = (issue: IssueDetails, phase: IssuePhase) => {
  const translationKey = getProcessPhaseKey(issue?.process, phase);
  const translation = t(translationKey);
  return translation !== translationKey ? translation : phase.name;
};

function progressToolTip(issue: IssueDetails, phase: IssuePhase) {
  if (!phase.shouldSkip) {
    return null;
  }

  const translationKey = `${getProcessPhaseKey(issue?.process, phase)}.skipped`;

  if (te(translationKey)) {
    return t(translationKey);
  }

  return null;
}

const { isAdmin } = useAuthUser();

function onProgressCheckCtrlClick(e: MouseEvent) {
  if (isAdmin.value && e.currentTarget) {
    const title = (e.currentTarget as HTMLElement).getAttribute('title');
    if (title) {
      navigator.clipboard.writeText(title);
    }
  }
}
</script>
<template>
  <div class="issue-progress-container">
    <ul class="issue-progress mb-1">
      <li
        v-for="(phase, index) in orderedPhases"
        :key="phase.id"
        :class="[
          'progress',
          {
            active: phase.id === issue.currentPhase?.id,
            done: issue.currentPhase && phase.order < issue.currentPhase.order,
            selected: modelValue === phase.id,
            disabled: phase.shouldSkip,
          },
        ]"
        @click="() => onPhaseClick(phase)"
      >
        <div
          class="progress-check"
          v-tooltip="{ content: progressToolTip(issue, phase), theme: 'primary' }"
        >
          <template v-if="issue.currentPhase && phase.order < issue.currentPhase.order">
            <div
              v-if="phase.shouldSkip"
              class="progress-skipped progress-status"
            >
              <Icon
                icon="Skip"
                :options="{ color: Colors.grey800 }"
              />
            </div>
            <div
              v-else
              class="progress-checkmark progress-status"
            >
              <Icon
                icon="Checkmark"
                :options="{ color: Colors.primary }"
              />
            </div>
          </template>
          <div
            v-else
            class="progress-index progress-status subtitle bold"
          >
            <Icon
              v-if="issue.currentPhase?.state === 'closed'"
              icon="Checkmark"
              :options="{ color: Colors.white }"
            />
            <span v-else>{{ index + 1 }}</span>
          </div>
        </div>
        <div class="progress-text">
          <div
            class="progress-name p--medium grey-600 bold"
            :title="isAdmin ? getProcessPhaseKey(issue?.process, phase) : ''"
            @click.ctrl="onProgressCheckCtrlClick"
          >
            {{ progressName(issue, phase) }}
          </div>
          <!-- GOTO1 -->
          <IssueProgressPhaseState
            v-if="issue.currentPhase && phase.order <= issue.currentPhase.order"
            :phase="phase"
            :issue="issue"
          ></IssueProgressPhaseState>
          <template v-else>
            <span class="p--small grey-600"><Translate t="issue.phase.state.not_started" /></span>
          </template>
        </div>
      </li>
    </ul>
    <ul class="issue-progress-bar">
      <li
        v-for="phase in orderedPhases"
        class="progress-bar"
        :class="{ done: (issue.currentPhase && phase.order < issue.currentPhase.order) || phase.state === 'closed' }"
        :key="phase.id"
      ></li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as media;

.issue-progress-container {
  display: flex;
  flex-direction: column;
}

.issue-progress {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.issue-progress-bar {
  display: flex;
}

.progress-bar {
  flex: 1 1 auto;
  height: 0.4rem;
  background-color: colors.$grey-200;

  &.done {
    background-color: colors.$primary;
  }
}

.progress {
  display: flex;
  align-items: center;
  @include media.laptop() {
    flex-basis: 20%; // Baseline 5 phases
    flex-grow: 1;
  }
  gap: 0.8rem;
  position: relative;
  &.active,
  &.done {
    cursor: pointer;
  }

  &.active {
    .progress-name {
      color: colors.$primary;
    }

    .progress-index {
      background-color: colors.$primary;
      color: white;
    }
  }

  &.selected {
    .progress-check {
      border: 0.1rem solid colors.$primary;

      @include media.laptop() {
        padding: 0.7rem;
      }
    }
  }

  &.disabled {
    cursor: no-drop;
  }
}

.progress-index {
  background-color: colors.$grey-200;
  color: colors.$grey-600;
}

.progress-check {
  display: flex;
  @include media.laptop() {
    padding: 0.8rem;
  }
}

.progress-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
}

.progress-checkmark {
  background-color: colors.$primary--xlight;
  height: 4rem;
  width: 4rem;
}

.progress-skipped {
  background-color: colors.$grey-200;
  height: 4rem;
  width: 4rem;
}

.progress-text {
  display: none;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;

  @include media.laptop() {
    display: flex;
  }
}
</style>
