import tokenService from '@/features/auth/tokenService';
import type { FilesZipInput } from '../models/FilesZipInput';

const fixUrl = (path: string) => (import.meta.env.VITE_BACKEND_URL ? import.meta.env.VITE_BACKEND_URL + path : path);

export default function useFileDownloading() {
  const getIssuesCsvUrl = () => {
    return fixUrl('/archives/issues/csv');
  };

  const getArchivesFilesUrl = ({ zipName, filePaths }: FilesZipInput) => {
    const base = fixUrl('/archives/files');

    const query = `zipName=${zipName}&filePaths=${filePaths.join(',')}`;
    return encodeURI(`${base}?${query}`);
  };

  const getArchiveIssueUrl = (issueId: ID) => {
    const url = `/archives/issues/${encodeURIComponent(issueId)}`;
    return fixUrl(url);
  };

  const getArchivePhaseUrl = (phaseId: ID, stepTypeId?: ID, iterationCounter?: number) => {
    const url = `/archives/issues/phases/${encodeURIComponent(phaseId)}`;
    const urlSearchParams = new URLSearchParams();
    if (stepTypeId !== undefined) {
      urlSearchParams.append('stepTypeId', stepTypeId.toString());
    }
    if (iterationCounter !== undefined) {
      urlSearchParams.append('iterationCounter', iterationCounter.toString());
    }
    const qs = urlSearchParams.toString();
    return fixUrl(url + (qs.length > 0 ? `?${qs}` : ''));
  };

  const fetchFileObjectUrl = async (url: string) => {
    const result = await fetch(url, { headers: { Authorization: `Bearer ${tokenService.getToken()}` } });
    const blob = await result.blob();
    return window.URL.createObjectURL(blob);
  };

  const downloadFileUrl = (url: string, fileName?: string) => {
    const a = document.createElement('a');
    a.href = url;
    if (fileName) {
      a.download = fileName;
    }
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return {
    getArchivesFilesUrl,
    getArchiveIssueUrl,
    getArchivePhaseUrl,
    getIssuesCsvUrl,

    fetchFileObjectUrl,
    downloadFileUrl,
  };
}
